var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"articleDetails"},[_c('div',{staticClass:"basicInfo"},[(_vm.articleDetails.title)?_c('p',{staticClass:"article-title",domProps:{"innerHTML":_vm._s(_vm.articleDetails.title)}}):_vm._e(),(_vm.articleDetails.abstractContent)?_c('div',{staticClass:"abstractContent"},[_vm._v(" 摘要： "),_c('p',{domProps:{"innerHTML":_vm._s(_vm.articleDetails.abstractContent)}})]):_vm._e(),(_vm.articleDetails.keywords)?_c('div',{staticClass:"keywords"},[_vm._v(" 关键词： "),_c('p',{domProps:{"innerHTML":_vm._s(_vm.articleDetails.keywords)}})]):_vm._e(),(_vm.articleDetails.authors)?_c('div',{staticClass:"authors"},[_vm._v(" 作者： "),_c('p',{domProps:{"innerHTML":_vm._s(_vm.articleDetails.authors)}})]):_vm._e(),(_vm.articleDetails.introduction)?_c('div',{staticClass:"introduction"},[_vm._v(" 作者单位： "),_c('p',{domProps:{"innerHTML":_vm._s(_vm.articleDetails.authors + _vm.articleDetails.introduction)}})]):_vm._e(),(_vm.articleDetails.magCn)?_c('div',{staticClass:"magCn"},[_vm._v(" 刊名： "),_c('p',{domProps:{"innerHTML":_vm._s(_vm.articleDetails.magCn)}})]):_vm._e(),(_vm.articleDetails.magEn)?_c('div',{staticClass:"magEn"},[_vm._v(" Journal： "),_c('p',{domProps:{"innerHTML":_vm._s(_vm.articleDetails.magEn)}})]):_vm._e(),(_vm.showYearIssue)?_c('div',{staticClass:"year"},[_vm._v(" 年，卷，期： "),_c('p',{domProps:{"innerHTML":_vm._s(
          _vm.articleDetails.year +
          ',' +
          _vm.articleDetails.publishVol +
          ',' +
          _vm.articleDetails.magNumber
        )}})]):_vm._e(),(_vm.articleDetails.columnBelong)?_c('div',{staticClass:"columnBelong"},[_vm._v(" 所属期刊栏目： "),_c('p',{domProps:{"innerHTML":_vm._s(_vm.articleDetails.columnBelong)}})]):_vm._e(),(_vm.articleDetails.doi)?_c('div',{staticClass:"doi"},[_vm._v(" 分类号： "),_c('p',{domProps:{"innerHTML":_vm._s(_vm.articleDetails.doi)}})]):_vm._e()]),_c('div',{staticClass:"echarts-info"},[(_vm.showYDWZYHJG)?_c('div',{staticClass:"echarts-item"},[_vm._m(0),_c('div',{style:({
          width: '100%',
          height: '420px'
        }),attrs:{"id":"chartYDWZYHJG"}})]):_vm._e(),(_vm.showYDWZYHQK)?_c('div',{staticClass:"echarts-item"},[_vm._m(1),_c('div',{style:({
          width: '100%',
          height: '420px'
        }),attrs:{"id":"chartYDWZYHQK"}})]):_vm._e()]),_c('div',{staticClass:"second-title table-title"},[_c('span',{staticStyle:{"position":"relative"}},[_vm._v(" 阅读的相关文章 "),_c('el-tooltip',{attrs:{"effect":"dark","content":"阅读该篇文章的用户也阅读了其他相关文章的详情","placement":"top"}},[_c('i',{staticClass:"el-icon-question",staticStyle:{"color":"#333333","position":"absolute","top":"3px","cursor":"pointer","right":"10px"}})])],1)]),_c('commonTable',{attrs:{"table-data":_vm.articleTableData,"column-data":_vm.articleColumn,"init-obj":_vm.articlePageObj,"show-handle":"","handle-text":"全文","handle-content":"查看"},on:{"onChangePageData":(pageObj) => {
        _vm.getArticleListData(pageObj)
      },"onHandleClick":_vm.openArticleDetails}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second-title"},[_c('span',[_vm._v("阅读该篇文章用户的机构分布")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second-title"},[_c('span',[_vm._v("阅读该篇文章用户的期刊分布")])])
}]

export { render, staticRenderFns }