<!-- articleDetails -->
<template>
  <div class="articleDetails">
    <div class="basicInfo">
      <!-- 标题 -->
      <p
        v-if="articleDetails.title"
        class="article-title"
        v-html="articleDetails.title"
      />
      <!-- 摘要 -->
      <div v-if="articleDetails.abstractContent" class="abstractContent">
        摘要：
        <p v-html="articleDetails.abstractContent" />
      </div>
      <!-- 关键词 -->
      <div v-if="articleDetails.keywords" class="keywords">
        关键词：
        <p v-html="articleDetails.keywords" />
      </div>
      <!-- 作者 -->
      <div v-if="articleDetails.authors" class="authors">
        作者：
        <p v-html="articleDetails.authors" />
      </div>
      <!-- 作者单位 -->
      <div v-if="articleDetails.introduction" class="introduction">
        作者单位：
        <p v-html="articleDetails.authors + articleDetails.introduction" />
      </div>
      <!-- 刊名 -->
      <div v-if="articleDetails.magCn" class="magCn">
        刊名：
        <p v-html="articleDetails.magCn" />
      </div>
      <!-- 英文刊名 -->
      <div v-if="articleDetails.magEn" class="magEn">
        Journal：
        <p v-html="articleDetails.magEn" />
      </div>
      <!-- 年卷期 -->
      <div v-if="showYearIssue" class="year">
        年，卷，期：
        <p
          v-html="
            articleDetails.year +
            ',' +
            articleDetails.publishVol +
            ',' +
            articleDetails.magNumber
          "
        />
      </div>
      <!-- 所属期刊栏目 -->
      <div v-if="articleDetails.columnBelong" class="columnBelong">
        所属期刊栏目：
        <p v-html="articleDetails.columnBelong" />
      </div>
      <!-- 分类号 -->
      <div v-if="articleDetails.doi" class="doi">
        分类号：
        <p v-html="articleDetails.doi" />
      </div>
    </div>
    <div class="echarts-info">
      <div v-if="showYDWZYHJG" class="echarts-item">
        <div class="second-title"><span>阅读该篇文章用户的机构分布</span></div>
        <div
          id="chartYDWZYHJG"
          :style="{
            width: '100%',
            height: '420px'
          }"
        />
      </div>
      <div v-if="showYDWZYHQK" class="echarts-item">
        <div class="second-title"><span>阅读该篇文章用户的期刊分布</span></div>
        <div
          id="chartYDWZYHQK"
          :style="{
            width: '100%',
            height: '420px'
          }"
        />
      </div>
    </div>
    <div class="second-title table-title">
      <span style="position: relative">
        阅读的相关文章
        <el-tooltip
          effect="dark"
          content="阅读该篇文章的用户也阅读了其他相关文章的详情"
          placement="top"
        >
          <i
            class="el-icon-question"
            style="
              color: #333333;
              position: absolute;
              top: 3px;
              cursor: pointer;
              right: 10px;
            "
          />
        </el-tooltip>
      </span>
    </div>
    <commonTable
      :table-data="articleTableData"
      :column-data="articleColumn"
      :init-obj="articlePageObj"
      show-handle
      handle-text="全文"
      handle-content="查看"
      @onChangePageData="
        (pageObj) => {
          getArticleListData(pageObj)
        }
      "
      @onHandleClick="openArticleDetails"
    />
  </div>
</template>

<script>
import { Get, ProjectConfig } from 'kjqk-commons/src/common/index.js'
import { mapState } from 'vuex'
import echarts from 'echarts'
import commonTable from '@comp/commonTable'
const api = ProjectConfig.communicationEffectStatistics
export default {
  name: 'ArticleDetails',
  components: { commonTable },
  props: {},
  data() {
    return {
      articleDetails: '',
      articleTableData: [],
      YDWZYHJGdata: '',
      YDWZYHQKdata: '',
      articleColumn: [
        {
          title: '文章标题',
          prop: 'title',
          special: true,
          notSortable: true,
          clickParam: 'url'
        },
        {
          title: '作者',
          prop: 'authors',
          notSortable: true
        },
        {
          title: '机构',
          prop: 'institutions',
          notSortable: true
        },
        {
          title: '发文期刊',
          prop: 'magCn',
          notSortable: true
        },
        {
          title: '年/卷/期',
          prop: 'pubYearStage',
          notSortable: true
        },
        {
          title: '关键词',
          prop: 'keywords',
          notSortable: true
        },
        {
          title: '阅读量',
          prop: 'articleReadLogCount',
          width: '120px',
          notSortable: true,
          tooltip: true,
          tooltipContent: ''
        }
      ],
      articlePageObj: {
        page: 1,
        size: 10
      }
    }
  },
  computed: {
    ...mapState('incrementService', ['magId']),
    showYearIssue() {
      return this.articleDetails.year
        ? true
        : this.articleDetails.publishVol
        ? true
        : !!this.articleDetails.magNumber
    },
    showYDWZYHJG() {
      return (
        this.YDWZYHJGdata &&
        this.YDWZYHJGdata.articleAuthorOrgList &&
        this.YDWZYHJGdata.articleAuthorOrgList.length > 0
      )
    },
    showYDWZYHQK() {
      return (
        this.YDWZYHQKdata &&
        this.YDWZYHQKdata.articleAuthorMagList &&
        this.YDWZYHQKdata.articleAuthorMagList.length > 0
      )
    }
  },
  watch: {
    magId(n) {
      if (n) {
        this.$nextTick(() => {
          this.getData()
        })
      } else {
        this.$nextTick(() => {
          this.initData()
        })
      }
    }
  },
  mounted() {
    if (this.magId) {
      this.getData()
    }
  },
  methods: {
    // 窗口大小改变重新渲染echarts
    adjustTable() {
      if (document.getElementById('chartYDWZYHJG')) {
        echarts.init(document.getElementById('chartYDWZYHJG')).resize()
      }
      if (document.getElementById('chartYDWZYHQK')) {
        echarts.init(document.getElementById('chartYDWZYHQK')).resize()
      }
    },
    initData() {
      this.articleTableData = []
      this.articleDetails = ''
      this.chartYDWZYHJG()
      this.chartYDWZYHQK()
    },
    getData() {
      let params = {
        taskId: this.$route.query.taskId,
        articleId: this.$route.query.articleId,
        page: 0,
        size: 10
      }
      // 文章基本信息
      this.getArticleDetails()
      // 阅读文章用户机构分布
      this.getChartData('YDWZYHJG', params, api.GetArticleAuthorOrg.url)
      // 阅读文章用户期刊分布
      this.getChartData('YDWZYHQK', params, api.GetArticleAuthorMag.url)
      // 阅读文章学者相关文章
      this.getArticleListData()
    },
    getArticleDetails() {
      const params = {
        ids: this.$route.query.articleId
      }
      let url = api.GetArticleAndAuthorInfo.url
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.articleDetails = data.content[0]
          this.articleColumn[6].tooltipContent = `阅读了《${this.articleDetails.title}》的用户也阅读了这篇文章的次数`
        }
      })
    },
    // 获取echarts数据
    getChartData(type, params, url) {
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          let _this = this
          if (type === 'YDWZYHJG') {
            _this.YDWZYHJGdata = data
            if (_this.showYDWZYHJG) {
              _this.$nextTick(() => {
                _this.chartYDWZYHJG(data)
              })
            }
          } else if (type === 'YDWZYHQK') {
            _this.YDWZYHQKdata = data
            if (_this.showYDWZYHQK) {
              _this.$nextTick(() => {
                _this.chartYDWZYHQK(data)
              })
            }
          }
        }
      })
    },
    // 阅读文章用户机构分布
    chartYDWZYHJG(data) {
      if (!document.getElementById('chartYDWZYHJG')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartYDWZYHJG'))
      const nameArr = []
      const countArr = []
      if (data && data.articleAuthorOrgList.length > 0) {
        data.articleAuthorOrgList.map((item) => {
          nameArr.push(item.orgName)
          countArr.push(item.count)
        })
      }
      myChart.setOption(
        {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          xAxis: [
            {
              type: 'category',
              data: nameArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: function (value) {
                  var res = value
                  if (res.length > 10) {
                    res = res.substring(0, 10) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '13%',
            bottom: '18%',
            top: '4%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1
            }
          ],
          series: [
            {
              name: '数量',
              type: 'bar',
              data: countArr,
              barWidth: 20,
              itemStyle: {
                normal: {
                  color: function () {
                    return '#265cca'
                  },
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            }
          ]
        },
        true
      )
    },
    // 阅读文章用户期刊分布
    chartYDWZYHQK(data) {
      if (!document.getElementById('chartYDWZYHQK')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartYDWZYHQK'))
      const nameArr = []
      const countArr = []
      if (data && data.articleAuthorMagList.length > 0) {
        data.articleAuthorMagList.map((item) => {
          nameArr.push(item.magName)
          countArr.push(item.count)
        })
      }
      myChart.setOption(
        {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          xAxis: [
            {
              type: 'category',
              data: nameArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: function (value) {
                  var res = value
                  if (res.length > 10) {
                    res = res.substring(0, 10) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '12%',
            bottom: '18%',
            top: '4%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1
            }
          ],
          series: [
            {
              name: '数量',
              type: 'bar',
              data: countArr,
              barWidth: 20,
              itemStyle: {
                normal: {
                  color: function () {
                    return '#265cca'
                  },
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            }
          ]
        },
        true
      )
    },
    // 阅读文章学者相关文章
    getArticleListData(pageParams = {}) {
      this.articleTableData = []
      const { page = 1, size = 10 } = pageParams
      const params = {
        page: page - 1,
        size,
        taskId: this.$route.query.taskId,
        articleId: this.$route.query.articleId
      }
      let url = api.GetReadRelatedArticleList.url
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          if (data) {
            const { content = [], totalElements = 0 } = data
            this.articlePageObj = {
              page,
              size,
              total: totalElements
            }
            this.articleTableData = content
            if (this.articleTableData.length > 0) {
              this.articleTableData.map((item) => {
                item.pubYearStage =
                  item.year +
                  '年第' +
                  item.publishVol +
                  '卷第' +
                  item.magNumber +
                  '期'
              })
            }
          }
        }
      })
    },
    // 打开全文链接
    openArticleDetails(item) {
      window.open(item.officialWebsiteUrl, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.articleDetails {
  padding: 20px;
  .basicInfo {
    div {
      margin-bottom: 4px;
      line-height: 18px;
      font-weight: 600;
      p {
        display: inline;
        font-weight: 500;
      }
    }
  }
  .article-title {
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 600;
  }
  .echarts-info {
    display: flex;
    justify-content: space-between;
    .echarts-item {
      width: 50%;
    }
  }
}
</style>
